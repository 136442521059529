@use '@sats-group/ui-lib/tokens/spacing';
@use '@sats-group/ui-lib/tokens/light';
@use '@sats-group/ui-lib/tokens/corner-radius';

.my-statistics {
  &__header {
    background-color: light.$background-secondary-default;
  }

  &__title {
    padding: spacing.$xl 0;
  }

  &__navigation {
    display: flex;
    align-items: center;
    gap: spacing.$s;
  }

  &__navigation-divider {
    padding: spacing.$xxs 0;
    border-top: 1px solid light.$ge-divider-default;
  }

  &__box {
    background-color: light.$surface-primary-default;
    border-radius: corner-radius.$s;
    margin: spacing.$l 0;

    @media (min-width: 800px) {
      margin: spacing.$xxl 0;
    }
  }

  &__box-top {
    border-bottom: 1px solid light.$ge-divider-default;
    padding: spacing.$m spacing.$l;
  }

  &__box-text {
    color: light.$on-surface-primary-alternate;
  }

  &__box-main-content {
    padding: spacing.$m spacing.$l;
  }

  &__list-title {
    margin-bottom: spacing.$s;
  }

  &__list {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  &__list-item {
    display: flex;
    align-items: center;
    margin-bottom: spacing.$xs;

    > * + * {
      padding-right: spacing.$xxs;
    }
  }

  &__checkmark {
    margin-right: spacing.$xs;
    margin-top: 4px; // NOTE: Hardcoded to center align the icon
    color: light.$ge-progress-bar-background;

    &--green {
      color: light.$ge-signal-success;
    }
  }

  &__sessions {
    background-color: light.$background-secondary-default;
    padding: spacing.$l 0;

    @media (min-width: 800px) {
      padding: spacing.$xxl 0;
    }
  }

  &__sessions-title {
    margin-bottom: spacing.$m;
  }

  &__session {
    border-bottom: 1px solid light.$ge-divider-default;
    display: flex;
    justify-content: space-between;
    padding: spacing.$xs 0;
  }
}
